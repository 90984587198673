<template>
  <img class="unselect" @click="login" src="/static/icon/ic_google_circle.svg">
</template>

<script>
  export default {
    name: "BtnSigninGoogle",
    methods: {
      login() {
        // test
        //const url = encodeURIComponent('http://localhost:8001/auth/user/signin/google/platformfy');
        const url = encodeURIComponent('https://master-dev.launchpack.co.kr:1111/auth/user/signin/google/platformfy');

        const scope = "https://www.googleapis.com/auth/userinfo.email " +
                "https://www.googleapis.com/auth/userinfo.profile";
        location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=707984287237-g3q2k9skg5sbmp2sn1e8eh90hgi7unc7.apps.googleusercontent.com&response_type=code&redirect_uri=${url}&scope=${scope}`
      }
    }
  }
</script>

<style lang="stylus" scoped>
  img
    width 52px
    height 52px
</style>
